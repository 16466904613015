import React from "react";
import Box from "@mui/material/Box";
import { Header } from "app/components/header";
import { Footer } from "app/components/footer";
import Container from "@mui/material/Container";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import { Breadcrumbs } from "app/components/breadcrumbs";
import { useRouteListener } from "app/hooks/useRouteListener";
import { SAMPLE_LOCATIONS_DATA } from "../generic-table/data";
import { SECTORS } from "app/pages/explorer/pages/sectors/data";
import { ORGANISATIONS } from "app/pages/explorer/pages/organisations/data";
import { ACTIVITIES } from "app/pages/home/explorer/components/live-data-updates/data";

export const Page: React.FC = () => {
  useRouteListener();

  const location = useLocation();

  const [countries, setCountries] = React.useState<
    { code: string; name: string }[]
  >([]);

  React.useEffect(() => {
    if (countries.length === 0) {
      fetch("/static/countries.json")
        .then((res) => res.json())
        .then((data) => setCountries(data))
        .catch((err) => console.log(err));
    }
  }, []);

  const getLocationName = (code: string) => {
    return (
      countries.find(
        (location) => location.code.toLowerCase() === code.toLowerCase()
      )?.name ?? code
    );
  };

  const getSectorName = (id: string) => {
    return SECTORS.find((s) => s.id === id)?.name ?? id;
  };

  const getOrganisationName = (id: string) => {
    return ORGANISATIONS.find((o) => o.iatiIdentifier === id)?.name ?? id;
  };

  const getActivityName = (id: string) => {
    return ACTIVITIES.find((a) => a.id === id)?.title ?? id;
  };

  const breadcrumbItems = React.useMemo(() => {
    if (location.pathname === "/publisher") {
      return [{ title: "Publisher", link: "/publisher" }];
    }
    if (location.pathname === "/explorer") {
      return [{ title: "Explore", link: "/explorer" }];
    }
    if (location.pathname === "/explorer/locations") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Locations", link: "/explorer/locations" },
      ];
    }
    const locationId = matchPath("/explorer/locations/:id", location.pathname)
      ?.params.id;
    if (locationId) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Locations", link: "/explorer/locations" },
        { title: getLocationName(locationId), link: location.pathname },
      ];
    }
    if (location.pathname === "/explorer/organisations") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Organisations", link: "/explorer/organisations" },
      ];
    }
    const organisationId = matchPath(
      "/explorer/organisations/:id",
      location.pathname
    )?.params.id;
    if (organisationId) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Organisations", link: "/explorer/organisations" },
        { title: getOrganisationName(organisationId), link: location.pathname },
      ];
    }
    if (location.pathname === "/explorer/sectors") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Sectors", link: "/explorer/sectors" },
      ];
    }
    const sectorId = matchPath("/explorer/sectors/:id", location.pathname)
      ?.params.id;
    if (sectorId) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Sectors", link: "/explorer/sectors" },
        { title: getSectorName(sectorId), link: location.pathname },
      ];
    }
    if (location.pathname === "/explorer/activities") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Activities", link: "/explorer/activities" },
      ];
    }
    const activityId = matchPath("/explorer/activities/:id", location.pathname)
      ?.params.id;
    if (activityId) {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Activities", link: "/explorer/activities" },
        { title: getActivityName(activityId), link: location.pathname },
      ];
    }
    if (location.pathname === "/explorer/favourites") {
      return [
        { title: "Explore", link: "/explorer" },
        { title: "Your Favourites", link: "/explorer/favourites" },
      ];
    }
    if (location.pathname === "/data-hub") {
      return [{ title: "Data Hub", link: "/data-hub" }];
    }
    return [];
  }, [location.pathname, countries]);

  return (
    <React.Fragment>
      <Header />
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          paddingBottom: "50px",
          minHeight: "calc(100vh - 51px - 277px)",
          "@media (max-width: 1200px)": {
            padding: "0 16px",
          },
        }}
      >
        {location.pathname === "/" ? (
          <Box height={50} />
        ) : (
          <Breadcrumbs items={breadcrumbItems} />
        )}

        <Box id="main">
          <Outlet />
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
