import { Box, Grid, Typography } from "@mui/material";
import { PageHeader } from "app/components/page-header";
import { ReactComponent as ExplorerHeaderIcon } from "app/assets/vectors/ExplorerHeader.svg";
import React from "react";
import { ExplorerSearch } from "./components/search";
import { ShareButton } from "app/components/share-button";
import { DownloadButton } from "app/components/download-button";
import TransactionTypeSelect from "./components/transaction-type";
import { YearSelectButton } from "../../components/year-select-button";
import { InsightsBlock } from "./components/insights-block";
import { KeyProjectionsBlock } from "./components/key-projections-block";

export const Explorer: React.FC = () => {
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="AIDA Explorer: Discover Data Insights"
        subtitle="Discover insights, trends, and funding activities around the globe. Uncover valuable information to fuel your data-driven decisions."
        icon={<ExplorerHeaderIcon />}
      />
      <Box height={40} />
      <Grid display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h2" fontSize={"32px"}>
          Real-Time Insights: Aid Data of 2022
        </Typography>

        <Grid display={"flex"} alignItems={"center"} columnGap={"8px"}>
          <ExplorerSearch />
          <TransactionTypeSelect />
          <YearSelectButton />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={40} />
      <InsightsBlock />
      <Box height={40} />
      <Grid display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h2" fontSize={"32px"}>
          The World in 2050: Key Projections
        </Typography>

        <Grid display={"flex"} alignItems={"center"} columnGap={"8px"}>
          <ExplorerSearch />
          <TransactionTypeSelect />
          <YearSelectButton />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={40} />

      <KeyProjectionsBlock />
    </Box>
  );
};
