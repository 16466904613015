import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  GridColDef,
  GridRowHeightParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { formatDate } from "app/utils/formatDate";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Link from "@mui/material/Link";

export interface TableProps {
  rows: Array<object>;
  columns: GridColDef[];
  withSearch?: boolean;
  withAddFileButton?: boolean;
  withColumnsButton?: boolean;
  withFilterButton?: boolean;
  headerFontSize?: string;
  withSortButton?: boolean;
  withCollapseButton?: boolean;
  getDetailPanelContent?: (row: any) => React.ReactNode;
  autoHeight?: boolean;
  getRowHeight?: (params: GridRowHeightParams) => number;
}

export const GET_CONVERTED_FILE_ACTIVITIES_COLUMNS = (
  seeTheReportAction: (value: string) => void
) => {
  const onSeeTheReportClick = (value: string) => () => {
    seeTheReportAction(value);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
    },
    {
      field: "date",
      headerName: "Creation Date",
      width: 120,
      renderCell: (params) => formatDate(params.value as string),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "Activity Title",
      width: 700,
      flex: 1,
    },
    {
      field: "type",
      headerName: "File Type",
      width: 100,
      align: "center",
    },
    {
      field: "status",
      headerName: "Validity Status",
      width: 120,
      align: "center",
      display: "flex",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "60px",
              color: "#fff",
              height: "19px",
              display: "flex",
              fontSize: "12px",
              fontWeight: "700",
              borderRadius: "5px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: params.value === "Valid" ? "#2EA390" : "#ED6060",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "validityReportId",
      headerName: "Validity Report",
      width: 150,
      align: "center",
      display: "flex",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={onSeeTheReportClick(params.value as string)}
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: colors.text.title,
              textDecoration: "underline",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            See the Report
          </Button>
        );
      },
    },
  ];
  return columns;
};

export interface ConvertedFileActivity {
  id: string;
  date: string;
  title: string;
  type: string;
  status: string;
  validityReportId: string;
}

export const CONVERTED_FILE_ACTIVITIES_ROWS: ConvertedFileActivity[] = [
  {
    id: "1",
    date: "2021-09-01",
    title: "Activity 1",
    type: "Activity",
    status: "Valid",
    validityReportId: "1",
  },
  {
    id: "2",
    date: "2021-09-02",
    title: "Activity 2",
    type: "Activity",
    status: "Invalid",
    validityReportId: "2",
  },
];

export const GET_CONVERTED_FILES_COLUMNS = (
  changePublishStatus: (fileId: string, value: boolean) => void
) => {
  const onChangePublishStatus = (fileId: string, value: boolean) => () => {
    changePublishStatus(fileId, value);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
    },
    {
      field: "date",
      headerName: "Creation Date",
      width: 120,
      renderCell: (params) => formatDate(params.value as string),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "File Name",
      width: 700,
      flex: 1,
    },
    {
      field: "type",
      headerName: "File Type",
      width: 100,
      align: "center",
    },
    {
      field: "status",
      headerName: "Validity Status",
      width: 120,
      align: "center",
      display: "flex",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "60px",
              color: "#fff",
              height: "19px",
              display: "flex",
              fontSize: "12px",
              fontWeight: "700",
              borderRadius: "5px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: params.value === "Valid" ? "#2EA390" : "#ED6060",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "isPublished",
      headerName: "Publishing Status",
      width: 150,
      align: "center",
      display: "flex",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <FormControl
            fullWidth
            sx={{
              ".MuiInputBase-root": {
                width: "100%",
                height: "22px",
                borderRadius: "5px",
                background: params.value ? "#2EA390" : colors.secondary.orange,
              },
              ".MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "700",
                textAlign: "start",
                paddingLeft: "8px",
                paddingRight: "8px",
                color: colors.primary.white,
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderStyle: "none",
              },
              ".MuiSelect-icon": {
                color: colors.primary.white,
              },
              ".MuiMenuItem-root": {
                fontSize: "12px",
                fontWeight: "700",
              },
            }}
          >
            <Select
              value={params.value ? 1 : 0}
              IconComponent={KeyboardArrowDown}
              onChange={onChangePublishStatus(
                params.rowNode.id.toString(),
                !(params.value as boolean)
              )}
            >
              <MenuItem
                value={1}
                sx={{
                  color: "#2EA390",
                }}
              >
                Published
              </MenuItem>
              <MenuItem
                value={0}
                sx={{
                  color: colors.secondary.orange,
                }}
              >
                Draft
              </MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "url",
      headerName: "XML File",
      width: 150,
      align: "center",
      display: "flex",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          target="_blank"
          href={params.value as string}
          sx={{
            fontSize: "12px",
            color: colors.text.title,
          }}
        >
          Download XML
        </Link>
      ),
    },
  ];
  return columns;
};

export interface ConvertedFile {
  id: string;
  date: string;
  title: string;
  type: string;
  status: string;
  isPublished: boolean;
  activityCount: number;
  url: string;
}

export const CONVERTED_FILES_ROWS: ConvertedFile[] = [
  {
    id: "1",
    date: "2021-09-01",
    title: "ITS Establishement of Nagpur-Mumbai Super Communication Expressway",
    type: "Activity",
    status: "Invalid",
    isPublished: false,
    activityCount: 1,
    url: "https://files.aida.tools/activties/1",
  },
  {
    id: "2",
    date: "2021-09-02",
    title:
      "Competitiveness Improvement of Agriculture and Allied Sectors Project in the Union Territory of Jammu and Kashmir",
    type: "Activity",
    status: "Valid",
    isPublished: false,
    activityCount: 1,
    url: "https://files.aida.tools/activties/2",
  },
];
