import { Box, Collapse, Divider, Grid, Typography } from "@mui/material";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import { colors } from "app/theme";
import React from "react";
import { ResultItem, ResultItems } from "./data";
import { SimpleTable } from "app/components/simple-table";

const ExpandedResultsBlock = (props: { data: ResultItems }) => {
  const [glossaryOpen, setGlossaryOpen] = React.useState(false);

  const glossaryItems = [
    {
      title: "Output",
      description:
        "Results of the activity that came about as a direct effect of your work and specific, what is done, and what communities are reached. For example, X number of individuals.",
    },
    {
      title: "Outcome",
      description:
        "Results of the activity that produce an effect on the overall communities or issues you serve. For example lower rate of infection after a vaccination programme.",
    },
    {
      title: "Impact",
      description:
        "The long term effects of the outcomes, that lead to larger, over arching results, such as improved life-expectancy.",
    },
  ];
  return (
    <React.Fragment>
      <Box sx={{ padding: "20px", background: colors.secondary.lightGrey }}>
        <Typography
          variant="h3"
          fontSize={"24px"}
          lineHeight={"normal"}
          display={"flex"}
          alignItems={"center"}
          columnGap={"20px"}
          onClick={() => setGlossaryOpen(!glossaryOpen)}
          component={"button"}
          sx={{
            border: "none",
            background: "none",
            cursor: "pointer",
            "> svg": {
              transition: "transform 0.3s",
            },
          }}
        >
          Results Glossary
          <ArrowDown
            stroke="#0C162A"
            width={28}
            height={28}
            transform={`rotate(${glossaryOpen ? "180" : "0"})`}
          />
        </Typography>
        <Collapse timeout={300} in={glossaryOpen}>
          <Grid marginTop={"20px"} container columnSpacing={"37px"}>
            {glossaryItems.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Typography
                  variant="h4"
                  fontSize={"18px"}
                  lineHeight={"normal"}
                  color={"#ffffff"}
                  marginBottom={"10px"}
                  borderRadius={"5px"}
                  padding={"6px 10px"}
                  width={"max-content"}
                  sx={{
                    background: colors.shades.blue[400],
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={"12px"}
                  lineHeight={"normal"}
                >
                  {item.description}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Box>
      <Divider sx={{ margin: "20px 0" }} />

      <Grid container columnSpacing={"37px"}>
        <Grid item xs={12} md={4}>
          <ResultGroup data={props.data.outputs} title="Outputs" />
        </Grid>
        <Grid item xs={12} md={4}>
          <ResultGroup data={props.data.outcome} title="Outcome" />
        </Grid>
        <Grid item xs={12} md={4}>
          <ResultGroup data={props.data.impact} title="Impact" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ExpandedResultsBlock;

const ResultGroup = (props: {
  data: ResultItems["outcome" | "impact" | "outputs"];
  title: string;
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Box>
      <Typography
        variant="h3"
        fontSize={"24px"}
        lineHeight={"normal"}
        display={"flex"}
        alignItems={"center"}
        columnGap={"20px"}
        onClick={() => setOpen(!open)}
        component={"button"}
        sx={{
          border: "none",
          background: "none",
          cursor: "pointer",
          "> svg": {
            transition: "transform 0.3s",
          },
        }}
      >
        {props.title} ({props.data.length})
        <ArrowDown
          stroke="#0C162A"
          width={28}
          height={28}
          transform={`rotate(${open ? "180" : "0"})`}
        />
      </Typography>
      <Collapse in={open}>
        {props.data.map((d) => (
          <React.Fragment key={d.name}>
            <Box height={20} />
            <SingleResult {...d} />
          </React.Fragment>
        ))}
      </Collapse>
    </Box>
  );
};

const SingleResult = (props: ResultItem) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Box>
      <Box
        sx={{
          background: "#F1F3F5",
          padding: "10px",
        }}
      >
        <Typography
          color={colors.secondary.grey}
          variant="h4"
          fontSize={"18px"}
          lineHeight={"normal"}
        >
          {props.name}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontSize={"14px"}
        lineHeight={"20px"}
        fontWeight={"400"}
        marginTop={"20px"}
        color={colors.secondary.grey}
      >
        {props.description}
      </Typography>

      <Collapse in={open} timeout={300}>
        <Typography
          variant="h6"
          fontSize={"12px"}
          lineHeight={"normal"}
          color={colors.secondary.grey}
          marginTop={"20px"}
        >
          Indicator
        </Typography>
        <Typography
          variant="h5"
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={"400"}
          marginTop={"10px"}
          color={colors.secondary.grey}
        >
          {props.indicator}
        </Typography>
        <Box height={20} />
        <SimpleTable
          columns={[
            {
              headerName: "Facet",
              field: "facet",
            },
            {
              headerName: "Baseline",
              field: "baseline",
              renderCell: (row) => (
                <Typography
                  variant="body1"
                  fontSize={"12px"}
                  lineHeight={"normal"}
                  fontWeight={"700"}
                  color={"#ffffff"}
                  width={"100%"}
                  textAlign={"center"}
                  sx={{
                    background: colors.charts[1],
                    borderRadius: "5px",
                    padding: "2px",
                  }}
                >
                  {row.row.baseline}
                </Typography>
              ),
            },
            {
              headerName: "Target",
              field: "target",
              renderCell: (row) => (
                <Typography
                  variant="body1"
                  fontSize={"12px"}
                  lineHeight={"normal"}
                  fontWeight={"700"}
                  color={"#ffffff"}
                  textAlign={"center"}
                  sx={{
                    background: colors.charts[9],
                    borderRadius: "5px",
                    padding: "2px",
                  }}
                  width={"100%"}
                >
                  {row.row.target}
                </Typography>
              ),
            },
            {
              headerName: "Actual",
              field: "actual",
              renderCell: (row) => (
                <Typography
                  variant="body1"
                  fontSize={"12px"}
                  lineHeight={"normal"}
                  fontWeight={"700"}
                  color={"#ffffff"}
                  sx={{
                    background: colors.charts[7],
                    borderRadius: "5px",
                    padding: "2px",
                  }}
                  width={"100%"}
                  textAlign={"center"}
                >
                  {row.row.actual}
                </Typography>
              ),
            },
            {
              headerName: "%",
              field: "percentage",
            },
            {
              headerName: "Period",
              field: "period",
            },
          ]}
          rows={[props]}
          variant="vertical"
          boxStyle={{
            padding: "6px 10px",
          }}
        />
        <Typography
          variant="h6"
          fontSize={"12px"}
          lineHeight={"normal"}
          color={colors.secondary.grey}
          marginTop={"20px"}
        >
          Reference
        </Typography>
        <Box height={10} />
        <Grid container columnSpacing={"10px"}>
          <Grid item xs={12} md={6}>
            <SimpleTable
              columns={[
                {
                  headerName: "VOCAB",
                  field: "vocab",
                },
              ]}
              rows={[props]}
              variant="vertical"
              boxStyle={{
                padding: "6px 10px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleTable
              columns={[
                {
                  headerName: "CODE",
                  field: "code",
                },
              ]}
              rows={[props]}
              variant="vertical"
              boxStyle={{
                padding: "6px 10px",
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          // component={"button"}
          variant="button"
          color={colors.primary.blue}
          onClick={() => setOpen(!open)}
          width={"max-content"}
          sx={{
            cursor: "pointer",
          }}
        >
          [{`${open ? "-" : "+"}`}]
        </Typography>
      </Box>
    </Box>
  );
};
