import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SECTORS } from "../../data";
import { Box, Grid, SelectChangeEvent } from "@mui/material";
import { ReactComponent as ExplorerSectorHeaderIcon } from "app/assets/vectors/ExplorerSectorHeaderIcon.svg";
import { PageHeader } from "app/components/page-header";
import { SelectField } from "app/components/select-field";
import TransactionTypeSelect from "app/pages/explorer/components/transaction-type";
import { YearSelectButton } from "app/components/year-select-button";
import { ShareButton } from "app/components/share-button";
import { DownloadButton } from "app/components/download-button";
import { InsightsBlock } from "./components/insights-block";

const ExplorerSector = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const sector = SECTORS.find((sec) => sec.id.toString() === id);
  const handleSectorChange = (e: SelectChangeEvent) => {
    navigate(`/explorer/sectors/${e.target.value}`);
  };

  const [expandedBlock, setExpandedBlock] = React.useState<
    | "locations"
    | "results"
    | "sdgs"
    | "networks"
    | "sectors"
    | "impact"
    | "organisations"
    | "recent-activities"
    | null
  >(null);

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title={sector?.name!}
        subtitle="Showcasing sector-specific data for Emergency Response."
        icon={<ExplorerSectorHeaderIcon />}
      />
      <Box height={30} />
      <Grid
        justifyContent={"space-between"}
        alignItems={"center"}
        display={"flex"}
      >
        <SelectField
          id="location-select"
          options={SECTORS.map((s) => ({
            label: s.name,
            value: String(s.id),
          }))}
          placeholder="Select Another Sector"
          value={""}
          onChange={handleSectorChange}
          variant="outlined"
          width="300px"
        />
        <Grid display={"flex"} alignItems={"center"} columnGap={"8px"}>
          <TransactionTypeSelect />
          <YearSelectButton />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={30} />
      <InsightsBlock
        expandedBlock={expandedBlock}
        setExpandedBlock={handleSetExpandedBlock}
      />
    </Box>
  );
};

export default ExplorerSector;
