import {
  Box,
  Button,
  Popover,
  SelectChangeEvent,
  Typography,
  Grid,
} from "@mui/material";
import { colors } from "app/theme";
import React from "react";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import { SelectField } from "app/components/select-field";

const ButtonStyle = {
  display: "flex",
  alignItems: "center",
  columnGap: "5px",
  padding: "8px 10px",
};

export const YearSelectButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [yearData, setYearData] = React.useState({
    startYear: "2022",
    endYear: "2023",
    startMonth: "January",
    endMonth: "December",
  });

  const years = [
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];

  const months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setYearData({
      ...yearData,
      [event.target.name]: event.target.value,
    });
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "year-select-popup" : undefined;

  return (
    <Box>
      <Button
        aria-describedby={id}
        variant="general"
        sx={ButtonStyle}
        onClick={handleOpen}
      >
        {yearData.startYear}
        <ArrowDown transform={`rotate(${open ? "180" : "0"})`} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        //   keepMounted
        sx={{
          zIndex: 3,
          ".MuiPopover-paper": {
            background: "transparent",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "5px",
            border: `1px solid ${colors.secondary.iconGray}`,
            background: colors.secondary.lightGrey,
            padding: "16px",
            width: "319px",
            marginTop: "13px",
          }}
        >
          <Typography variant="button" lineHeight={"20px"}>
            {yearData.startMonth.substring(0, 3)} {yearData.startYear} -{" "}
            {yearData.endMonth.substring(0, 3)} {yearData.endYear}{" "}
            <Typography variant="body2" fontSize={"12px"}>
              selected.
            </Typography>
          </Typography>
          <Box height={10} />
          <Grid
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Typography variant="button">from</Typography>
            <Grid display={"flex"} alignItems={"center"} columnGap={"10px"}>
              <Box width={130} flexShrink={"0"}>
                <SelectField
                  id="startMonth"
                  value={yearData.startMonth}
                  options={months}
                  onChange={handleChange}
                  variant="outlined"
                  name="startMonth"
                  label="Month"
                />
              </Box>
              <Box width={100} flexShrink={"0"}>
                <SelectField
                  id="startYear"
                  value={yearData.startYear}
                  options={years}
                  onChange={handleChange}
                  variant="outlined"
                  name="startYear"
                  label="Year"
                />
              </Box>
            </Grid>
          </Grid>

          <Box height={10} />
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Typography variant="button">to</Typography>
            <Grid display={"flex"} alignItems={"center"} columnGap={"10px"}>
              <Box width={130} flexShrink={"0"}>
                <SelectField
                  id="endMonth"
                  value={yearData.endMonth}
                  options={months}
                  onChange={handleChange}
                  variant="outlined"
                  name="endMonth"
                  label="Month"
                />
              </Box>
              <Box width={100} flexShrink={"0"}>
                <SelectField
                  id="endYear"
                  value={yearData.endYear}
                  options={years}
                  onChange={handleChange}
                  variant="outlined"
                  name="endYear"
                  label="Year"
                />
              </Box>
            </Grid>
          </Grid>

          <Box height={16} />
          <Box display={"flex"} justifyContent={"end"} columnGap={"10px"}>
            <Button variant="general">Reset</Button>
            <Button variant="general" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
