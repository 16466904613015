import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { LineSeriesOption, LineChart as EChartsLine } from "echarts/charts";
import {
  GridComponent,
  MarkPointComponent,
  MarkPointComponentOption,
  LegendComponent,
  LegendComponentOption,
} from "echarts/components";
import { LineChartProps } from "app/components/charts/line/data";
import { formatNumber } from "app/utils/formatNumber";

echarts.use([
  EChartsLine,
  SVGRenderer,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
]);

export const LineChart: React.FC<LineChartProps> = (props: LineChartProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: `${props.id}-line-chart`,
  });

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });
      const yAxisMin = Math.min(
        ...props.data.series.map((d) => Math.min(...d.values))
      );

      const yAxisMax = Math.max(
        ...props.data.series.map((d) => Math.max(...d.values))
      );

      const option: echarts.ComposeOption<
        LineSeriesOption | LegendComponentOption
      > = {
        color: [
          "#C89105",
          "#FFC145",
          "#FFF377",
          "#B23D3E",
          "#42D1C1",
          "#D8AFB2",
          "#E66E72",
          "#F0A8AA",
          "#F8D4D5",
          "#FCF0F1",
          "#BDA4FF",
          "#B6B6B6",
        ],
        grid: {
          top: 80,
          left: 20,
          right: 20,
          bottom: 20,
          containLabel: true,
        },
        legend: {
          data: props.data.series.map((s) => s.name),
          show: !!props.showLegend,
          left: 40,
          textStyle: {
            fontSize: 12,
            padding: [0, 0, 0, 0],
          },
          icon: "circle",
          itemHeight: 10,
          itemWidth: 10,
          pageButtonPosition: "start",
          formatter: "{name}           ",
        },
        xAxis: {
          type: "category",
          data: props.data.xAxisValues,
          boundaryGap: false,
        },
        yAxis: {
          name: props.yAxisLabelName,
          type: "value",
          splitLine: {
            show: true,
            interval: "auto",
            lineStyle: {
              color: ["#E0E6F1"],
              width: 0.688,
            },
          },
          splitNumber: 3,
        },
        series: props.data.series.map((s) => ({
          name: s.name,
          data: s.values,
          type: "line",
          lineStyle: {
            color: props.lineColor,
            width: props.lineThickness ?? 2,
          },
          step: props.step,
          symbol: "circle",
          symbolSize: props.symbolSize ?? 4,
          itemStyle: {
            color: props.lineColor,
          },
        })),
      };

      const signalLineOption: echarts.ComposeOption<
        LineSeriesOption | MarkPointComponentOption
      > = {
        grid: {
          top: 40,
          left: 20,
          right: 20,
          bottom: 20,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: props.data.xAxisValues,
          min: 0,
          axisLine: {
            show: props.hideXaxisLine ? false : true,
            lineStyle: {
              color: colors.secondary.lightGrayText,
            },
          },
          axisTick: {
            show: false,
          },
          boundaryGap: false,
          axisLabel: {
            formatter: function (value: any, index: number) {
              // Show the label only for the first and last categories
              if (index === 0 || index === props.data.xAxisValues.length - 1) {
                return value;
              }
              return "";
            },
          },
        },
        yAxis: {
          name: props.yAxisLabelName,
          type: "value",
          splitLine: {
            show: false,
          },
          min: "dataMin",
          axisLine: {
            show: true,
            lineStyle: {
              color: colors.secondary.lightGrayText,
            },
          },
          axisLabel: {
            formatter: function (value: any, index: number) {
              // Get the min and max values of the y-axis
              const minValue = yAxisMin;
              const maxValue = yAxisMax;

              // Show the label only for the min and max values
              if (value === minValue || value === maxValue) {
                return formatNumber(value, true);
              }
              return "";
            },
          },
        },
        series: props.data.series.map((s) => ({
          data: s.values,
          type: "line",
          lineStyle: {
            color: colors.secondary.blue,
            width: 3,
          },
          symbol: "circle",
          symbolSize: (value: any, params: any) => {
            // Show the symbol only for the min and max values
            if (
              params.dataIndex === 0 ||
              params.dataIndex === props.data.xAxisValues.length - 1
            ) {
              return 20;
            }
            return 0;
          },
          itemStyle: {
            color: colors.secondary.blue,
          },
          markPoint: {
            data: s.values.map((value, index) => ({
              name: "{b}",
              coord: [index, yAxisMin], // Position based on the data
              label: {
                show:
                  index === 0 || index === props.data.xAxisValues.length - 1,
                position: "bottom",
                formatter: () => formatNumber(value, true), // Convert to 'K' format
                color: "#1976d2", // Color to match your design
                fontWeight: "bold",
                fontSize: "12px",
                offset: [0, 20],
              },
              symbolSize: 0, // Hide the default markPoint symbol
            })),
          },
        })),
      };

      chart.setOption(
        props.variant === "signalLine" ? signalLineOption : option
      );
      setStateChart(chart);
    }
  }, [
    containerRef.current,
    props.data,
    props.yAxisLabelName,
    props.variant,
    props.width,
    props.height,
  ]);

  return (
    <Box
      id={`${props.id}-line-chart`}
      ref={containerRef}
      width={props?.width ?? "100%"}
      height={props?.height ?? "450px"}
    />
  );
};
