import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FavouriteIcon } from "app/assets/vectors/jsx/FavouriteIcon";

export const TRANSACTION_COLUMNS: GridColDef[] = [
  {
    headerName: "Transaction Type",
    field: "transactionType",

    width: 180,
  },
  { headerName: "Date", field: "date", width: 150 },
  { headerName: "Description", field: "description", flex: 1 },
  {
    headerName: "Provider",
    field: "provider",
    headerAlign: "center",
    align: "center",

    width: 150,
  },
  {
    headerName: "Receiver",
    field: "receiver",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
  {
    headerName: "Value",
    field: "value",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
];

export const TRANSACTION_TABLE_DATA = [
  {
    id: "1",
    transactionType: "Disbursement",
    date: "2021-01-01",
    description: "EF XI-IATI-EC_NEAR-2020-PCC-413822-SCR.CTR.413822.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "3,000,000 EUR",
  },
  {
    id: "2",
    transactionType: "Outgoing Commitment",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "3",
    transactionType: "Incoming Commitment",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "4",
    transactionType: "Disbursement",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "5",
    transactionType: "Incoming Commitment",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "6",
    transactionType: "Disbursement",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "7",
    transactionType: "Incoming Commitment",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
  {
    id: "8",
    transactionType: "Disbursement",
    date: "2020-11-16",
    description: "REF XI-IATI-EC_FPI-2023-PC-19810-SCR.CTR.441250.01",
    provider: "Provider Name",
    receiver: "Receiver Name",
    value: "700,00 EUR",
  },
];

export const RELATED_ACTIVITIES = [
  {
    id: "1",
    title: "ITS Establishement of Nagpur-Mumbai Super Communication Expressway",
    country: "Multiple Countries",
    status: "Pipeline/Identification",
    plannedStartDate: "30 June 2024",
    plannedEndDate: "30 December 2027",
  },
  {
    id: "2",
    title:
      "Competitiveness Improvement of Agriculture and Allied Sectors Project in the Union Territory of Jammu and Kashmir",
    country: "India",
    status: "Suspended",
    plannedStartDate: "30 June 2024",
    plannedEndDate: "30 December 2027",
  },
];

export const RELATED_ACTIVITY_COLUMNS: GridColDef[] = [
  {
    field: "favourite",
    headerName: "Favourite",
    width: 76,
    align: "center",

    renderCell: (params: any) => (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FavouriteIcon />
      </Box>
    ),
  },
  {
    field: "title",
    headerName: "Activity Title",
    width: 500,
    flex: 1,
  },
  {
    field: "country",
    headerName: "Country(s)",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedStartDate",
    headerName: "Planned Start Date",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedEndDate",
    headerName: "Planned End Date",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
];
