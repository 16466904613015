import React from "react";
import { InsightsBlockProps } from "./data";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { colors } from "app/theme";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import {
  LineChartSampleData,
  SignalLineChartSampleData,
} from "app/components/charts/line/data";
import { LineChart } from "app/components/charts/line";
import { SDGChart } from "app/components/charts/sdg";
import { SDGSampleData, SDGSampleData2 } from "app/components/charts/sdg/data";
import { NetworkChart } from "app/components/charts/network";
import { NetworkSampleData } from "app/components/charts/network/data";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { BarChart } from "app/components/charts/bar";
import { BarChartStackedSampleData } from "app/components/charts/bar/data";
import { PieChart } from "app/components/charts/pie";
import { PieChartSampleData } from "app/components/charts/pie/data";
import { ChartsBlock } from "app/components/grid-blocks/charts";
import { ArrowFall, ArrowRise } from "app/assets/vectors/jsx/arrow";
import { ImpactChart } from "app/components/charts/impact";
import { IMPACT_CHART_ITEMS_EXTRA } from "app/components/charts/impact/data";
import { CtaFavouriteIcon } from "app/assets/vectors/jsx/CtaFavouriteIcon";
import { CtaPublisherIcon } from "app/assets/vectors/jsx/CtaPublisherIcon";
import { CtaLocationIcon } from "app/assets/vectors/jsx/CtaLocationIcon";
import { CtaOrganisationIcon } from "app/assets/vectors/jsx/CtaOrganisationIcon";
import { CtaSectorIcon } from "app/assets/vectors/jsx/CtaSectorIcon";
import { CtaActivityIcon } from "app/assets/vectors/jsx/CtaActivityIcon";
import { YearSelectWidget } from "app/components/year-select-widget";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { GeomapChart } from "app/components/charts/geomap";
import { GeomapSampleData } from "app/components/charts/geomap/data";
import { Table } from "app/components/table";
import { IMPACT_COLUMNS } from "app/components/charts/impact/tableData";
import { ScrollIntoView } from "app/components/scroll-into-view";

export const InsightsBlock = () => {
  const [expandedBlock, setExpandedBlock] = React.useState<
    | "locations"
    | "results"
    | "sdgs"
    | "networks"
    | "sectors"
    | "impact"
    | "budgetLines"
    | "organisations"
    | null
  >(null);

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };
  const overview = React.useMemo(() => {
    const items = [
      { name: "Disbursements and Expenditures", value: "USD 226 billion" },
      { name: "Activities", value: "826,474" },
      { name: "Organisations", value: "1528" },
      { name: "Recipient Countries", value: "218" },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        title="Overview"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD 226 billion
            </span>{" "}
            was spent for
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {" "}
              218 recipient countries
            </span>{" "}
            in 2023.
          </React.Fragment>
        }
        content={<OverviewBlock items={items} />}
      />
    );
  }, []);

  const locations = React.useMemo(() => {
    const items = [
      {
        code: "so",
        name: "Somalia",
        funds: "USD 9.51 bln",
        activities: 459,
      },
      {
        code: "ye",
        name: "Yemen",
        funds: "USD 9.51 bln",
        activities: 326,
      },
      {
        code: "ss",
        name: "South Sudan",
        funds: "USD 6.65 bln",
        activities: 764,
      },
      {
        code: "af",
        name: "Afghanistan",
        funds: "USD 5.36 bln",
        activities: 325,
      },
    ];
    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          title="Locations"
          onExpand={() => handleSetExpandedBlock("locations")}
          description={
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                Somalia, Yemen, South Sudan
              </span>{" "}
              and{" "}
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                Afghanistan{" "}
              </span>
              were the largest recipient countries of 2022.
            </React.Fragment>
          }
          content={<LocationsBlock items={items} />}
          expanded={expandedBlock === "locations"}
        />
        {expandedBlock === "locations" && (
          <Box
            height="650px"
            padding="0 30px 20px 30px"
            bgcolor={colors.primary.white}
          >
            <Divider />
            <Box height={50} />

            <GeomapChart
              data={GeomapSampleData}
              showLegend
              showTooltip
              height="500px"
              variant="explorer"
              showZoomWidget
            />
          </Box>
        )}
      </React.Fragment>
    );
  }, [expandedBlock]);

  const results = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Results"
        onExpand={() => handleSetExpandedBlock("results")}
        height="100%"
        description={
          <React.Fragment>
            In 2022, available results show activities surpassed their target
            results by{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              4.8%
            </span>
            on average.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            {expandedBlock === "results" ? null : (
              <Typography
                variant="body2"
                fontSize={"12px"}
                lineHeight={"normal"}
              >
                Actual results and their relation to the target results within
                the last five years:
              </Typography>
            )}

            <LineChart
              id="results-line"
              data={LineChartSampleData}
              yAxisLabelName="% of success"
              height={expandedBlock === "results" ? "594px" : "376px"}
              lineColor={colors.secondary.red}
            />
            {expandedBlock === "results" ? (
              <Box
                sx={{
                  padding: "10px 0",
                  marginTop: "20px",
                }}
              >
                <YearSelectWidget />
              </Box>
            ) : null}
          </React.Fragment>
        }
        expanded={expandedBlock === "results"}
      />
    );
  }, [expandedBlock]);

  const sdgs = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sustainable Development Goals"
        onExpand={() => handleSetExpandedBlock("sdgs")}
        description={
          <React.Fragment>
            In 2022,{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              125,000 activities
            </span>{" "}
            were contributing to the SDGs.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              There was a 21% increase in 2022 in the number of organisations
              publishing data on SDGs, compared to 2021. <br />
              Top six trending Sustainable Development Goals of 2022:
            </Typography>
            <Box height={28} />
            <SDGChart
              data={expandedBlock === "sdgs" ? SDGSampleData : SDGSampleData2}
              expanded={expandedBlock === "sdgs"}
              labelGroup="Activities"
            />
          </React.Fragment>
        }
        expanded={expandedBlock === "sdgs"}
      />
    );
  }, [expandedBlock]);

  const networks = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        onExpand={() => handleSetExpandedBlock("networks")}
        height="100%"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              1528 organisations
            </span>{" "}
            have published data on their development and humanitarian resources
            and activities.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              See the organisation network by their sectors:
            </Typography>{" "}
            <NetworkChart
              data={NetworkSampleData}
              showZoomWidget={expandedBlock === "networks"}
            />
          </React.Fragment>
        }
        expanded={expandedBlock === "networks"}
      />
    );
  }, [expandedBlock]);

  const budgetLines = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Budget Lines"
        onExpand={() => handleSetExpandedBlock("budgetLines")}
        height="100%"
        description={
          <React.Fragment>
            Budget lines by sectors show that{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              Productive Sectors
            </span>{" "}
            were the most funded sectors of 2022.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <BarChart
              barWidth="40"
              yAxisLabelName="USD"
              xAxisLabelRotate={0}
              data={BarChartStackedSampleData}
            />
            {expandedBlock === "budgetLines" ? (
              <Box
                sx={{
                  padding: "10px 0",
                }}
              >
                <YearSelectWidget />
              </Box>
            ) : null}
          </React.Fragment>
        }
        expanded={expandedBlock === "budgetLines"}
      />
    );
  }, [expandedBlock]);

  const organisations = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Organisations"
        onExpand={() => handleSetExpandedBlock("organisations")}
        description={
          <React.Fragment>
            Top 10 organisations provides in total of{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              678,2 million development aid
            </span>{" "}
            in 2022. Which makes up the{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              85% of the total development aid.
            </span>
            were the most funded sectors of 2022.
          </React.Fragment>
        }
        content={
          <PieChart
            id="organisations-pie"
            donut
            half
            data={PieChartSampleData}
            unit="USD"
            height={expandedBlock === "organisations" ? "500px" : "350px"}
          />
        }
        expanded={expandedBlock === "organisations"}
      />
    );
  }, [expandedBlock]);

  const sectors = React.useMemo(() => {
    const Legend = (props: {
      percentageChange: number;
      year: number;
      value: string;
      name: string;
    }) => {
      return (
        <Box>
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {props.name}
          </Typography>
          <Box height={20} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                color={colors.secondary.blue}
                lineHeight={"normal"}
              >
                {props.value}
              </Typography>

              <Typography
                variant="body2"
                fontSize={"12px"}
                lineHeight={"normal"}
              >
                {props.year}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <Typography
                color={
                  props.percentageChange < 0 ? colors.secondary.red : "#2EA390"
                }
                variant="button"
              >
                {props.percentageChange > 0 ? "+" : null}
                {props.percentageChange}
              </Typography>

              {props.percentageChange < 0 ? <ArrowFall /> : <ArrowRise />}
            </Box>
          </Box>
        </Box>
      );
    };

    const items = [
      {
        name: "Disaster Prevention and Preparedness",
        value: "180K",
        percentageChange: 21.01,
        year: 2023,
      },
      {
        name: "Development Food Assistance",
        value: "250K",
        percentageChange: -17.34,
        year: 2023,
      },
      {
        name: "Energy policy and administrative management",
        value: "107K",
        percentageChange: -12.34,
        year: 2023,
      },
      {
        name: "Environmental policy and administrative management",
        value: "309K",
        percentageChange: 11.01,
        year: 2023,
      },

      {
        name: "Disaster Prevention and Preparedness",
        value: "180K",
        percentageChange: 21.01,
        year: 2023,
      },
      {
        name: "Development Food Assistance",
        value: "250K",
        percentageChange: -17.34,
        year: 2023,
      },
      {
        name: "Energy policy and administrative management",
        value: "107K",
        percentageChange: -12.34,
        year: 2023,
      },
      {
        name: "Environmental policy and administrative management",
        value: "309K",
        percentageChange: 11.01,
        year: 2023,
      },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sectors"
        onExpand={() => handleSetExpandedBlock("sectors")}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              Disaster Prevention and Preparedness
            </span>{" "}
            was the sector to have the highest percentage of growth for 2022.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              Sectors with significant funding rate changes for 2022:
            </Typography>
            <Box height={20} />
            <ChartsBlock
              items={items
                .slice(0, expandedBlock === "sectors" ? 8 : 4)
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <LineChart
                      id={`sector-${index}`}
                      data={SignalLineChartSampleData}
                      yAxisLabelName="USD"
                      variant="signalLine"
                      height="250px"
                    />
                    <Box height={10} />
                    <Legend {...item} />
                  </React.Fragment>
                ))}
            />
            {expandedBlock === "sectors" ? (
              <Box
                sx={{
                  padding: "10px 0",
                  marginTop: "20px",
                }}
              >
                <YearSelectWidget />
              </Box>
            ) : null}
          </React.Fragment>
        }
        expanded={expandedBlock === "sectors"}
      />
    );
  }, [expandedBlock]);

  const impact = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Impact"
        onExpand={() => handleSetExpandedBlock("impact")}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              Over 12,000 activities
            </span>{" "}
            went above and beyond their target results in 2022, showcasing
            noteworthy achievements and positive change.
          </React.Fragment>
        }
        content={
          expandedBlock === "impact" ? (
            <Box>
              <Table
                withSearch
                rows={IMPACT_CHART_ITEMS_EXTRA}
                columns={IMPACT_COLUMNS}
                headerFontSize="12px"
              />
            </Box>
          ) : (
            <ImpactChart items={IMPACT_CHART_ITEMS_EXTRA.slice(0, 4)} />
          )
        }
        expanded={expandedBlock === "impact"}
      />
    );
  }, [expandedBlock]);

  const expandedBlockView = React.useMemo(() => {
    switch (expandedBlock) {
      case "locations":
        return locations;
      case "results":
        return results;
      case "sdgs":
        return sdgs;
      case "networks":
        return networks;
      case "budgetLines":
        return budgetLines;
      case "organisations":
        return organisations;
      case "sectors":
        return sectors;
      case "impact":
        return impact;
      default:
        return null;
    }
  }, [
    expandedBlock,
    locations,
    results,
    sdgs,
    networks,
    budgetLines,
    organisations,
    sectors,
    impact,
  ]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Locations"),
    [locations]
  );
  const resultsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Results"),
    [results]
  );

  const sdgsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sustainable Development Goals"),
    [sdgs]
  );

  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );
  const budgetLinesBlockHeight = React.useMemo(
    () => getBlockHeight("block-Budget Lines"),
    [budgetLines]
  );

  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Organisations"),
    [organisations]
  );

  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sectors"),
    [sectors]
  );

  const impactBlockHeight = React.useMemo(
    () => getBlockHeight("block-Impact"),
    [impact]
  );

  return (
    <Grid container spacing={4}>
      {expandedBlock && (
        <Grid item xs={12}>
          <ScrollIntoView trigger={expandedBlock} />
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} md={7}>
        {overview}
        <Box sx={{ height: 20 }} />
        {expandedBlock !== "locations" ? (
          locations
        ) : (
          <ChartPlaceholder height={locationsBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5}>
        {expandedBlock !== "results" ? (
          results
        ) : (
          <ChartPlaceholder height={resultsBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5.7}>
        {expandedBlock !== "sdgs" ? (
          sdgs
        ) : (
          <ChartPlaceholder height={sdgsBlockHeight} />
        )}
        <Box sx={{ height: 20 }} />
        <Box
          sx={{
            gap: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CtaCommonBtn
            label="Your Favourites"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaFavouriteIcon />}
          />
          <CtaCommonBtn
            label="Publish Your Data"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaPublisherIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6.3}>
        {expandedBlock !== "networks" ? (
          networks
        ) : (
          <ChartPlaceholder height={networksBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5}>
        {expandedBlock !== "budgetLines" ? (
          budgetLines
        ) : (
          <ChartPlaceholder height={budgetLinesBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={7}>
        {expandedBlock !== "organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}

        <Box
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
          }}
        >
          <CtaCommonBtn
            label="Discover Locations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaLocationIcon />}
          />
          <CtaCommonBtn
            label="Discover Organisations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaOrganisationIcon />}
          />
          <CtaCommonBtn
            label="Discover Sectors"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaSectorIcon />}
          />
          <CtaCommonBtn
            label="Search & Filter Activities"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaActivityIcon />}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={12}>
        {expandedBlock !== "sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        {expandedBlock !== "impact" ? (
          impact
        ) : (
          <ChartPlaceholder height={impactBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
