import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Expand from "@mui/icons-material/UnfoldMore";
import Collapse from "@mui/icons-material/UnfoldLess";
import Share from "@mui/icons-material/ShareOutlined";
import { GenericPageBlockItemProps } from "app/components/generic-page-block-item/data";

export const GenericPageBlockItem: React.FC<GenericPageBlockItemProps> = (
  props: GenericPageBlockItemProps
) => {
  return (
    <Box
      id={`block-${props.title}`}
      sx={{
        gap: "20px",
        display: "flex",
        padding: "20px 30px",
        flexDirection: "column",
        background: colors.primary.white,
        height: props.height ?? "auto",
      }}
    >
      {" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            fontSize="12px"
            marginBottom="5px"
            textTransform="uppercase"
            color={colors.text.title}
          >
            {props.title}
          </Typography>

          <Typography
            flex="1"
            fontSize="18px"
            component="div"
            fontWeight="700"
            color={colors.text.title}
          >
            {props.description}
          </Typography>
        </Box>
        {props.rightToolbar}
        {!props.rightToolbar && (props.toolbarShare || props.toolbarExpand) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              "> button": {
                padding: "4px",
              },
            }}
          >
            {props.toolbarExpand && (
              <IconButton onClick={props.onExpand}>
                {props.expanded ? <Collapse /> : <Expand />}
              </IconButton>
            )}
            {props.toolbarShare && (
              <IconButton>
                <Share fontSize="small" />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          borderColor: colors.secondary.iconGray,
        }}
      />
      <Box>{props.content}</Box>
    </Box>
  );
};
