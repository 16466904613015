import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { PieSeriesOption, PieChart as EChartsPie } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { PieChartProps } from "app/components/charts/pie/data";
import { formatNumber } from "app/utils/formatNumber";
import { shuffle } from "lodash";
import { useSessionStorage } from "react-use";
import { GeomapChartTooltip } from "../geomap/tooltip";

echarts.use([EChartsPie, SVGRenderer, GridComponent]);

export const PieChart: React.FC<PieChartProps> = (props: PieChartProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: `${props.id}-pie-chart`,
  });

  const [isLocked, setIsLocked] = useSessionStorage<boolean>(
    "pie-is-locked",
    false
  );
  const [tooltip, setTooltip] = useSessionStorage<{
    id: string;
    label: string;
    value: number;
  } | null>("pie-tooltip", null);

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<PieSeriesOption> = {
        color:
          props.data.length < 5
            ? colors.charts.filter((_, index) => index % 2 === 0)
            : colors.charts,
        series: {
          type: "pie",
          endAngle: props.half ? 360 : "auto",
          startAngle: props.half ? 180 : 0,
          data: props.data,
          center: [
            props.showTooltip ? "35%" : "50%",
            props.half ? "70%" : "50%",
          ],
          radius: props.donut ? ["60%", "100%"] : ["0%", "100%"],
          bottom: props.half ? -50 : 20,
          padAngle: 0.2,
          top: props.half ? 0 : 20,
          label: {
            show: props.showLabel ?? true,
            fontSize: props.labelFontSize ?? "12px",
            minMargin: 0,
            fontFamily: "'Inter', sans-serif",
            formatter: (params) => {
              return `${params.name}\n${props.unit} ${formatNumber(params.value as number)}\n(${params.percent}%)`;
            },
            rich: {
              boldName: {
                fontSize: "14px",
                fontWeight: "bold",
              },
            },
          },
        },
      };

      chart.setOption(option);

      chart.on("click", (params: any) => {
        const locked = sessionStorage.getItem("pie-is-locked");
        const gtooltip = JSON.parse(
          sessionStorage.getItem("pie-tooltip")?.toString() ?? "{code: ''}"
        );

        if (params.data) {
          if (
            params.data.name === gtooltip?.label &&
            locked &&
            locked === "true"
          ) {
            setIsLocked(false);
          } else {
            setTooltip({
              id: params.data?.id,
              label: params.data?.name,
              value: params.data.value,
            });
            setIsLocked(true);
          }
        } else {
          setIsLocked(false);
          if (tooltip) {
            setTooltip(null);
          }
        }
      });
      chart.on("mouseover", (params: any) => {
        const locked = sessionStorage.getItem("pie-is-locked");
        if (params.data && (!locked || locked === "false")) {
          setTooltip({
            id: params.data?.id,
            label: params.data?.name,
            value: params.data.value,
          });
        }
      });
      chart.on("mouseout", () => {
        const locked = sessionStorage.getItem("pie-is-locked");
        if (!locked || locked === "false") {
          setTooltip(null);
        }
      });
      setStateChart(chart);
    }
  }, [
    containerRef.current,
    props.data,
    props.half,
    props.donut,
    props.unit,
    props.height,
    props.labelFontSize,
  ]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        id={`${props.id}-pie-chart`}
        ref={containerRef}
        width="100%"
        height={props?.height ?? "450px"}
      />
      {props.showTooltip && tooltip && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "30%",
          }}
        >
          <GeomapChartTooltip
            isLocked={isLocked}
            label={"Global"}
            value={tooltip.value}
            subLabel={tooltip.label}
            variant={props.variant}
            lockUnlock={() => {
              setTooltip(null);
              setIsLocked(!isLocked);
            }}
            buttonLink={`/explorer/organisation/${tooltip.id}`}
            buttonText="Organisation Page"
          />
        </Box>
      )}
    </Box>
  );
};
