import { Box, Divider, Grid, SxProps } from "@mui/material";
import React from "react";

interface ChartsBlockProps {
  items: React.ReactNode[];
}

export const ChartsBlock: React.FC<ChartsBlockProps> = (
  props: ChartsBlockProps
) => {
  const GridStyle: SxProps = {
    // paddingTop: "16px",

    h4: {
      fontSize: "18px",
      fontWeight: "700",
    },
    "> div": {
      gap: "10px",
      display: "flex",
      paddingBottom: "16px",
      paddingRight: "16px",
      flexDirection: "column",
      "&:not(:nth-of-type(4n+0))": {
        borderRight: "1px solid #E0E0E0",
      },
    },
  };
  return (
    <React.Fragment>
      {Array(props.items.length / 4)
        .fill(0)
        .map((_, i) => (
          <Box
            key={i}
            sx={
              i !== props.items.length / 4 - 1
                ? {
                    paddingBottom: "20px",
                    marginBottom: "36px",
                    borderBottom: "1px solid #E0E0E0",
                  }
                : {}
            }
          >
            <Grid container spacing={2} rowGap={2} sx={GridStyle}>
              {props.items.slice(i * 4, i * 4 + 4).map((item) => (
                <Grid item sm={12} md={3}>
                  <Box>{item}</Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
    </React.Fragment>
  );
};
