import React from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { PageHeader } from "app/components/page-header";
import { ACTIVITIES } from "app/pages/home/explorer/components/live-data-updates/data";
import { ReactComponent as ExplorerActivitiesHeaderIcon } from "app/assets/vectors/ExplorerActivitiesHeaderIcon.svg";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { SelectField } from "app/components/select-field";
import { Table } from "app/components/table";
import {
  RELATED_ACTIVITIES,
  RELATED_ACTIVITY_COLUMNS,
  TRANSACTION_COLUMNS,
  TRANSACTION_TABLE_DATA,
} from "./tableData";
import DescriptionBlock from "./components/description-block";
import TransactionsBlock from "./components/transactions-block";
import { colors } from "app/theme";
import { NetworkChart } from "app/components/charts/network";
import { NetworkSampleData } from "app/components/charts/network/data";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import SearchBigIcon from "app/assets/vectors/jsx/SearchBigIcon";
import ExpandedResultsBlock from "./components/expanded-results-block";
import { ACTIVITIES_COLUMNS } from "app/pages/home/explorer/components/live-data-updates/tableColumns";

export const ExplorerActivity = () => {
  const { id } = useParams();
  const activity = ACTIVITIES.find((activity) => activity.id === id);

  const [expandedBlock, setExpandedBlock] = React.useState<
    "results" | "networks" | null
  >(null);

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };

  const networks = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        onExpand={() => handleSetExpandedBlock("networks")}
        height="100%"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              7 organisations
            </span>{" "}
            are collaborating on this activity.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              See the organisation network by their sectors:
            </Typography>{" "}
            <NetworkChart
              data={NetworkSampleData}
              showZoomWidget={expandedBlock === "networks"}
              height={expandedBlock === "networks" ? "330px" : "190px"}
              zoom={expandedBlock === "networks" ? 3 : 2}
            />
          </React.Fragment>
        }
        expanded={expandedBlock === "networks"}
      />
    );
  }, [expandedBlock]);

  const results = React.useMemo(() => {
    const dummyText = `IO1: CSO supported initiatives increase awareness of climate change and the environment and support the circular economy for plastic waste management. and services for persons with disabilities, especially girls and boys;`;
    const indicator = `% of Dili residents in program targeted area reporting practicing a 3Rs behavior (avoid single use of plastic)`;

    const dummyData = {
      description: dummyText,
      indicator: indicator,
      facet: "N/A",
      baseline: "N/A",
      target: "N/A",
      actual: "N/A",
      percentage: "N/A",
      period: "2019-12-01 : 2022-11-30",
      vocab: "99",
      code: "63864",
    };
    const resultsData = {
      outputs: [
        {
          name: "Output 1",
          ...dummyData,
        },
        {
          name: "Output 2",
          ...dummyData,
        },
        {
          name: "Output 3",
          ...dummyData,
        },
      ],
      outcome: [
        {
          name: "Outcome 1",
          ...dummyData,
        },
        {
          name: "Outcome 2",
          ...dummyData,
        },
        {
          name: "Outcome 3",
          ...dummyData,
        },
      ],
      impact: [
        {
          name: "Impact 1",
          ...dummyData,
        },
        {
          name: "Impact 2",
          ...dummyData,
        },
        {
          name: "Impact 3",
          ...dummyData,
        },
      ],
    };

    const items = [
      { name: "Outputs(s)", value: resultsData.outputs.length },
      { name: "Outcome", value: resultsData.outcome.length },
      { name: "Impact", value: resultsData.impact.length },
    ];
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Activity Results"
        onExpand={() => handleSetExpandedBlock("results")}
        height="100%"
        description={
          <React.Fragment>
            There's{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              9 results{" "}
            </span>
            related to this activity.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            {expandedBlock === "results" ? (
              <ExpandedResultsBlock data={resultsData} />
            ) : (
              <React.Fragment>
                <OverviewBlock items={items} />
                <Box height={20} />
                <Box
                  sx={{
                    padding: "15px",
                    background: colors.secondary.lightGrey,
                    display: "flex",
                    columnGap: "10px",
                    "> svg": {
                      flexShrink: 0,
                    },
                  }}
                >
                  <SearchBigIcon />

                  <Box>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={"700"}
                      color={"black"}
                      lineHeight={"20px"}
                      marginBottom={"5px"}
                    >
                      Expand to see detailed results.
                    </Typography>

                    <Typography
                      variant="body1"
                      color={"#373D43"}
                      fontSize={"12px"}
                      lineHeight={"normal"}
                    >
                      Results of the activity includes; output(s), outcome and
                      impact. Every indicator has it's on description and
                      measures. Expand to see the activity's outputs, outcome
                      and impact.
                    </Typography>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        }
        expanded={expandedBlock === "results"}
      />
    );
  }, [expandedBlock]);

  const expandedBlockView = React.useMemo(() => {
    switch (expandedBlock) {
      case "results":
        return results;
      case "networks":
        return networks;

      default:
        return null;
    }
  }, [results, networks]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const resultsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Activity Results"),
    [results]
  );

  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title={activity?.title!}
        subtitle={``}
        icon={<ExplorerActivitiesHeaderIcon />}
      />
      <Box height={30} />

      <Grid container spacing={"30px"}>
        {expandedBlock && (
          <Grid item xs={12}>
            {expandedBlockView}
          </Grid>
        )}
        <Grid item xs={12}>
          <DescriptionBlock />
        </Grid>

        <Grid item xs={12}>
          <TransactionsBlock />
        </Grid>

        <Grid item xs={12} md={6}>
          {expandedBlock !== "networks" ? (
            networks
          ) : (
            <ChartPlaceholder height={networksBlockHeight} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {expandedBlock !== "results" ? (
            results
          ) : (
            <ChartPlaceholder height={resultsBlockHeight} />
          )}
        </Grid>

        <Grid item xs={12}>
          <GenericPageBlockItem
            title="Related Activities"
            description={
              <React.Fragment>
                There's currently{" "}
                <span style={{ color: colors.primary.blue }}>
                  2 activities{" "}
                </span>
                related to this activity.
              </React.Fragment>
            }
            content={
              <React.Fragment>
                <Table
                  withSearch
                  rows={RELATED_ACTIVITIES}
                  columns={RELATED_ACTIVITY_COLUMNS}
                  headerFontSize="12px"
                />
              </React.Fragment>
            }
            toolbarShare
          />
        </Grid>
      </Grid>
    </Box>
  );
};
