import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface OverviewBlockProps {
  items: {
    name: string;
    value: string | React.ReactNode;
  }[];
}

export const OverviewBlock: React.FC<OverviewBlockProps> = (
  props: OverviewBlockProps
) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingTop: "16px",
        h6: {
          fontSize: "12px",
          fontWeight: "400",
        },
        h3: {
          fontSize: "24px",
          fontWeight: "700",
        },
        div: {
          gap: "10px",
          display: "flex",
          paddingBottom: "16px",
          flexDirection: "column",
          "&:not(:last-child)": {
            borderRight: "1px solid #E0E0E0",
          },
        },
      }}
    >
      {props.items.map((item) => (
        <Grid item sm={12} md={12 / props.items.length}>
          <Typography variant="h6">{item.name}</Typography>
          <Typography variant="h3">{item.value}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
