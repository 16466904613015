import { Box, SelectChangeEvent, Slide, Typography } from "@mui/material";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { SelectField } from "app/components/select-field";
import React from "react";
import { TRANSACTION_COLUMNS, TRANSACTION_TABLE_DATA } from "../../tableData";
import { Table } from "app/components/table";
import { LineChart } from "app/components/charts/line";
import { TransactionsLineChartSampleData } from "app/components/charts/line/data";

const TransactionsBlock = () => {
  const [view, setView] = React.useState("table");

  const handleChange = (event: SelectChangeEvent) => {
    setView(event.target.value);
  };
  return (
    <GenericPageBlockItem
      toolbarShare
      title="Transactions"
      description={""}
      content={
        <React.Fragment>
          <SelectField
            width="106px"
            value={view}
            variant="standard"
            id="transactions-view-select"
            options={[
              { label: "Table View", value: "table" },
              { label: "Chart View", value: "chart" },
            ]}
            onChange={handleChange}
          />

          <Box sx={{ height: "20px" }} />
          {view === "table" ? (
            <Table
              rows={TRANSACTION_TABLE_DATA}
              columns={TRANSACTION_COLUMNS}
              headerFontSize="12px"
              getRowHeight={() => 31}
            />
          ) : (
            <Box>
              <Typography variant="h6" fontSize={"12px"}>
                Transaction Types
              </Typography>
              <LineChart
                id="transaction-line"
                yAxisLabelName="USD"
                data={TransactionsLineChartSampleData}
                symbolSize={10}
                showLegend
                step="end"
                lineThickness={1}
                height="470px"
              />
            </Box>
          )}
        </React.Fragment>
      }
    />
  );
};

export default TransactionsBlock;
