import React from "react";
import { InsightsBlockProps } from "./data";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { colors } from "app/theme";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import {
  LineChartSampleData,
  SignalLineChartSampleData,
} from "app/components/charts/line/data";
import { LineChart } from "app/components/charts/line";
import { SDGChart } from "app/components/charts/sdg";
import { SDGSampleData, SDGSampleData2 } from "app/components/charts/sdg/data";
import { NetworkChart } from "app/components/charts/network";
import { NetworkSampleData } from "app/components/charts/network/data";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { BarChart } from "app/components/charts/bar";
import { BarChartStackedSampleData } from "app/components/charts/bar/data";
import { PieChart } from "app/components/charts/pie";
import { PieChartSampleData } from "app/components/charts/pie/data";
import { ChartsBlock } from "app/components/grid-blocks/charts";
import { ArrowFall, ArrowRise } from "app/assets/vectors/jsx/arrow";
import { ImpactChart } from "app/components/charts/impact";
import { IMPACT_CHART_ITEMS_EXTRA } from "app/components/charts/impact/data";
import { CtaFavouriteIcon } from "app/assets/vectors/jsx/CtaFavouriteIcon";
import { CtaPublisherIcon } from "app/assets/vectors/jsx/CtaPublisherIcon";
import { CtaLocationIcon } from "app/assets/vectors/jsx/CtaLocationIcon";
import { CtaOrganisationIcon } from "app/assets/vectors/jsx/CtaOrganisationIcon";
import { CtaSectorIcon } from "app/assets/vectors/jsx/CtaSectorIcon";
import { CtaActivityIcon } from "app/assets/vectors/jsx/CtaActivityIcon";
import { useParams } from "react-router-dom";
import { SAMPLE_LOCATIONS_DATA } from "app/components/generic-table/data";
import { GeomapChart } from "app/components/charts/geomap";
import { GeomapSampleData } from "app/components/charts/geomap/data";
import { SECTORS } from "app/pages/explorer/pages/sectors/data";
import { Table } from "app/components/table";
import { ACTIVITIES } from "app/pages/home/explorer/components/live-data-updates/data";
import { ACTIVITIES_COLUMNS } from "app/pages/home/explorer/components/live-data-updates/tableColumns";
import { YearSelectWidget } from "app/components/year-select-widget";
import { ScrollIntoView } from "app/components/scroll-into-view";

export const InsightsBlock: React.FC<InsightsBlockProps> = (
  props: InsightsBlockProps
) => {
  const { id } = useParams();

  const sector = SECTORS.find((sec) => sec.id.toString() === id);

  const overview = React.useMemo(() => {
    const items = [
      { name: "Disbursements and Expenditures", value: "USD 105 billion" },
      { name: "Activities", value: "1098" },
      { name: "Planned Activities", value: "0" },
      { name: "Organisations", value: "145" },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        title="Overview"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD 226 billion
            </span>{" "}
            was spent for{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {" "}
              18 recipient countries{" "}
            </span>{" "}
            in 2023.
          </React.Fragment>
        }
        content={<OverviewBlock items={items} />}
      />
    );
  }, [sector]);

  const locations = React.useMemo(() => {
    const items = [
      {
        code: "so",
        name: "Somalia",
        funds: "USD 9.51 bln",
        activities: 459,
      },
      {
        code: "ye",
        name: "Yemen",
        funds: "USD 9.51 bln",
        activities: 326,
      },
      {
        code: "ss",
        name: "South Sudan",
        funds: "USD 6.65 bln",
        activities: 764,
      },
      {
        code: "af",
        name: "Afghanistan",
        funds: "USD 5.36 bln",
        activities: 325,
      },
    ];
    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          title="Locations"
          onExpand={() => props.setExpandedBlock("locations")}
          description={
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                Somalia, Yemen, South Sudan
              </span>{" "}
              and{" "}
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                Afghanistan{" "}
              </span>
              were the largest recipient countries of 2022.
            </React.Fragment>
          }
          content={<LocationsBlock items={items} />}
          expanded={props.expandedBlock === "locations"}
        />
        {props.expandedBlock === "locations" && (
          <Box
            height="650px"
            padding="0 30px 20px 30px"
            bgcolor={colors.primary.white}
          >
            <Divider />
            <Box height={50} />

            <GeomapChart
              data={GeomapSampleData}
              showLegend
              showTooltip
              height="500px"
              variant="explorer"
              showZoomWidget
            />
          </Box>
        )}
      </React.Fragment>
    );
  }, [props.expandedBlock]);

  const results = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Results"
        onExpand={() => props.setExpandedBlock("results")}
        height="100%"
        description={
          <React.Fragment>
            In 2022, available results show activities surpassed their target
            results by{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              4.8%
            </span>
            on average.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              Actual results and their relation to the target results within the
              last five years:
            </Typography>

            <LineChart
              id="results-line"
              data={LineChartSampleData}
              yAxisLabelName="% of success"
              height={props.expandedBlock === "results" ? "594px" : "376px"}
              lineColor={colors.secondary.red}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "results"}
      />
    );
  }, [props.expandedBlock]);

  const sdgs = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sustainable Development Goals"
        onExpand={() => props.setExpandedBlock("sdgs")}
        description={
          <React.Fragment>
            In 2022,{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              125,000 activities
            </span>{" "}
            were contributing to the SDGs.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              There was a 21% increase in 2022 in the number of organisations
              publishing data on SDGs, compared to 2021. <br />
              <br />
              {props.expandedBlock === "sdgs" ? null : (
                <>
                  Top six trending Sustainable Development Goals of 2022 for{" "}
                  {sector?.name} Sector:
                </>
              )}
            </Typography>
            <Box height={28} />
            <SDGChart
              data={
                props.expandedBlock === "sdgs" ? SDGSampleData : SDGSampleData2
              }
              expanded={props.expandedBlock === "sdgs"}
              labelGroup="Activities"
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sdgs"}
      />
    );
  }, [props.expandedBlock, sector]);

  const networks = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        onExpand={() => props.setExpandedBlock("networks")}
        height="100%"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              1528 organisations
            </span>{" "}
            have published data on their development and humanitarian resources
            and activities.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize={"12px"} lineHeight={"normal"}>
              See the organisation network by their sectors:
            </Typography>{" "}
            <NetworkChart
              data={NetworkSampleData}
              showZoomWidget={props.expandedBlock === "networks"}
            />
          </React.Fragment>
        }
        expanded={props.expandedBlock === "networks"}
      />
    );
  }, [props.expandedBlock]);

  const sectors = React.useMemo(() => {
    const Legend = (props: {
      percentageChange: number;
      year: number;
      value: string;
      name: string;
    }) => {
      return (
        <Box>
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {props.name}
          </Typography>
          <Box height={20} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                color={colors.secondary.blue}
                lineHeight={"normal"}
              >
                {props.value}
              </Typography>

              <Typography
                variant="body2"
                fontSize={"12px"}
                lineHeight={"normal"}
              >
                {props.year}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <Typography
                color={
                  props.percentageChange < 0 ? colors.secondary.red : "#2EA390"
                }
                variant="button"
              >
                {props.percentageChange > 0 ? "+" : null}
                {props.percentageChange}
              </Typography>

              {props.percentageChange < 0 ? <ArrowFall /> : <ArrowRise />}
            </Box>
          </Box>
        </Box>
      );
    };

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Sector Funding"
        onExpand={() => props.setExpandedBlock("sectors")}
        height="100%"
        description={
          <React.Fragment>
            Funding for Emergency Response sector has been{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              increased 1.4%
            </span>{" "}
            in 2022 compared to 2021 data.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Box height={64} />
            {props.expandedBlock === "sectors" ? (
              <LineChart
                id="results-line"
                data={SignalLineChartSampleData}
                yAxisLabelName="USD"
                height="594px"
                lineColor={colors.secondary.blue}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LineChart
                  id={`sector-funding`}
                  data={SignalLineChartSampleData}
                  yAxisLabelName="USD"
                  variant="signalLine"
                  height="250px"
                  width="80%"
                  hideXaxisLine
                />
              </Box>
            )}

            {props.expandedBlock === "sectors" ? null : (
              <>
                <Box height={10} />
                <Legend
                  name=""
                  value="180k"
                  percentageChange={1.42}
                  year={2022}
                />
              </>
            )}
            {props.expandedBlock === "sectors" ? (
              <Box
                sx={{
                  padding: "10px 0",
                  marginTop: "20px",
                }}
              >
                <YearSelectWidget />
              </Box>
            ) : null}
          </React.Fragment>
        }
        expanded={props.expandedBlock === "sectors"}
      />
    );
  }, [props.expandedBlock]);

  const organisations = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Organisations"
        onExpand={() => props.setExpandedBlock("organisations")}
        description={
          <React.Fragment>
            Top 10 organisations provides in total of{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              678,2 million development aid
            </span>{" "}
            in 2022. Which makes up the{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              85% of the total development aid.
            </span>
          </React.Fragment>
        }
        content={
          <PieChart
            id="organisations-pie"
            donut
            half
            data={PieChartSampleData}
            unit="USD"
            height={props.expandedBlock === "organisations" ? "500px" : "350px"}
          />
        }
        expanded={props.expandedBlock === "organisations"}
      />
    );
  }, [props.expandedBlock]);

  const impact = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Impact"
        onExpand={() => props.setExpandedBlock("impact")}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              Over 12,000 activities
            </span>{" "}
            went above and beyond their target results in 2022, showcasing
            noteworthy achievements and positive change.
          </React.Fragment>
        }
        content={
          <ImpactChart
            title="Here's a selection of the most successfull activities carried and and resulted in 2022 from European Commission - Humanitarian Aid & Civil Protection:"
            items={[...IMPACT_CHART_ITEMS_EXTRA]}
          />
        }
        expanded={props.expandedBlock === "impact"}
      />
    );
  }, [props.expandedBlock]);

  const recentActivities = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        title="Recent Activities"
        description={
          <React.Fragment>
            Currently{" "}
            <span style={{ color: colors.primary.blue }}>
              1,908 active activities{" "}
            </span>
            taking place for {sector?.name} in 2022.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              withSearch
              rows={ACTIVITIES}
              columns={ACTIVITIES_COLUMNS}
              headerFontSize="12px"
            />
          </React.Fragment>
        }
        toolbarShare
        toolbarExpand
        onExpand={() => props.setExpandedBlock("recent-activities")}
        expanded={props.expandedBlock === "recent-activities"}
      />
    );
  }, [props.expandedBlock]);

  const expandedBlockView = React.useMemo(() => {
    switch (props.expandedBlock) {
      case "locations":
        return locations;
      case "results":
        return results;
      case "sdgs":
        return sdgs;
      case "networks":
        return networks;
      case "organisations":
        return organisations;
      case "sectors":
        return sectors;
      case "impact":
        return impact;
      case "recent-activities":
        return recentActivities;
      default:
        return null;
    }
  }, [
    props.expandedBlock,
    locations,
    results,
    sdgs,
    networks,
    organisations,
    sectors,
    recentActivities,
    impact,
  ]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Locations"),
    [locations]
  );
  const resultsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Results"),
    [results]
  );

  const sdgsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sustainable Development Goals"),
    [sdgs]
  );

  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );
  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Organisations"),
    [organisations]
  );

  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Sector Funding"),
    [sectors]
  );

  const impactBlockHeight = React.useMemo(
    () => getBlockHeight("block-Impact"),
    [impact]
  );

  const recentActivitiesBlockHeight = React.useMemo(
    () => getBlockHeight("block-Recent Activities"),
    [recentActivities]
  );

  return (
    <Grid container spacing={4}>
      {props.expandedBlock && (
        <Grid item xs={12}>
          <ScrollIntoView trigger={props.expandedBlock} />
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} md={7}>
        {overview}
        <Box sx={{ height: 20 }} />
        {props.expandedBlock !== "locations" ? (
          locations
        ) : (
          <ChartPlaceholder height={locationsBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5}>
        {props.expandedBlock !== "results" ? (
          results
        ) : (
          <ChartPlaceholder height={resultsBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5.5}>
        {props.expandedBlock !== "sdgs" ? (
          sdgs
        ) : (
          <ChartPlaceholder height={sdgsBlockHeight} />
        )}
        <Box sx={{ height: 20 }} />
        <Box
          sx={{
            gap: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CtaCommonBtn
            label="Your Favourites"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaFavouriteIcon />}
          />
          <CtaCommonBtn
            label="Publish Your Data"
            variant="medium"
            bgColor="blue"
            bgIcon={<CtaPublisherIcon />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6.5}>
        {props.expandedBlock !== "networks" ? (
          networks
        ) : (
          <ChartPlaceholder height={networksBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={5}>
        {props.expandedBlock !== "sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={7}>
        {props.expandedBlock !== "organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}

        <Box
          sx={{
            gap: "20px",
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
          }}
        >
          <CtaCommonBtn
            label="Discover Locations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaLocationIcon />}
          />
          <CtaCommonBtn
            label="Discover Organisations"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaOrganisationIcon />}
          />
          <CtaCommonBtn
            label="Discover Sectors"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaSectorIcon />}
          />
          <CtaCommonBtn
            label="Search & Filter Activities"
            variant="small"
            bgColor="blue"
            bgIcon={<CtaActivityIcon />}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "impact" ? (
          impact
        ) : (
          <ChartPlaceholder height={impactBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={12}>
        {props.expandedBlock !== "recent-activities" ? (
          recentActivities
        ) : (
          <ChartPlaceholder height={recentActivitiesBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
