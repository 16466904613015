import { Box, Grid, SelectChangeEvent } from "@mui/material";
import { DownloadButton } from "app/components/download-button";
import { SAMPLE_LOCATIONS_DATA } from "app/components/generic-table/data";
import { PageHeader } from "app/components/page-header";
import { SelectField } from "app/components/select-field";
import { ShareButton } from "app/components/share-button";
import { YearSelectButton } from "app/components/year-select-button";
import TransactionTypeSelect from "app/pages/explorer/components/transaction-type";
import React from "react";
import { CircleFlag } from "react-circle-flags";
import { useNavigate, useParams } from "react-router-dom";
import { InsightsBlock } from "./components/insights-block";

const ExplorerLocation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [countries, setCountries] = React.useState<
    { code: string; name: string }[]
  >([]);

  React.useEffect(() => {
    if (countries.length === 0) {
      fetch("/static/countries.json")
        .then((res) => res.json())
        .then((data) => setCountries(data))
        .catch((err) => console.log(err));
    }
  }, []);

  const location = countries.find(
    (location) => location.code.toLowerCase() === id?.toLowerCase()
  );

  const handleLocationChange = (e: SelectChangeEvent) => {
    navigate(`/explorer/locations/${e.target.value}`);
  };

  const [expandedBlock, setExpandedBlock] = React.useState<
    | "locations"
    | "results"
    | "sdgs"
    | "networks"
    | "sectors"
    | "impact"
    | "budgetLines"
    | "organisations"
    | "recent-activities"
    | "key-locations"
    | "key-organisations"
    | "key-sectors"
    | "key-targets"
    | "geospatial-data"
    | null
  >(null);

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title={location?.name!}
        subtitle={`Showcasing country-specific data of ${location?.name}.`}
        icon={
          <Box
            sx={{
              marginRight: "20px",
            }}
          >
            <CircleFlag
              countryCode={id?.toLowerCase()!}
              width={60}
              height={60}
            />
          </Box>
        }
      />
      <Box height={30} />
      <Grid
        justifyContent={"space-between"}
        alignItems={"center"}
        display={"flex"}
      >
        <SelectField
          id="location-select"
          options={countries.map((s) => ({
            label: (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <CircleFlag
                  width={12}
                  height={12}
                  countryCode={s.code.toLowerCase()}
                />
                {s.name}
              </Box>
            ),
            value: s.code,
          }))}
          placeholder="Select Another Location"
          value={""}
          onChange={handleLocationChange}
          variant="outlined"
          width="300px"
        />
        <Grid display={"flex"} alignItems={"center"} columnGap={"8px"}>
          <TransactionTypeSelect />
          <YearSelectButton />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={30} />
      <InsightsBlock
        expandedBlock={expandedBlock}
        setExpandedBlock={handleSetExpandedBlock}
        countries={countries}
      />
    </Box>
  );
};

export default ExplorerLocation;
