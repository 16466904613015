import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Sort from "@mui/icons-material/Sort";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { TableProps } from "app/components/table/data";
import ControlPoint from "@mui/icons-material/ControlPoint";
import ViewColumnOutlined from "@mui/icons-material/ViewColumnOutlined";
import {
  DataGrid,
  GridToolbarProps,
  useGridApiContext,
  GridSlotsComponent,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridRow,
  GridRowProps,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";

const CustomGridToolbar = (props: GridToolbarProps) => {
  const navigate = useNavigate();
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
      }}
    >
      {props.showQuickFilter && (
        <GridToolbarQuickFilter
          variant="outlined"
          sx={{
            ".MuiInputBase-root": {
              width: "100%",
              height: "28px",
              borderRadius: "5px",
              background: colors.secondary.disableButton,
            },
            ".MuiInputBase-input": {
              fontSize: "14px",
              textAlign: "start",
              color: colors.secondary.grayText,
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderStyle: "none",
            },
            ".MuiSvgIcon-root": {
              color: colors.secondary.grayText,
            },
          }}
        />
      )}
      <Box>
        {props.showAddFileButton && (
          <IconButton onClick={() => navigate("/publisher")}>
            <ControlPoint fontSize="small" htmlColor={colors.text.title} />
          </IconButton>
        )}
        {props.showColumnsButton && (
          <IconButton>
            <ViewColumnOutlined
              fontSize="small"
              htmlColor={colors.text.title}
            />
          </IconButton>
        )}
        {props.showFilterButton && (
          <IconButton onClick={() => apiRef.current.showFilterPanel()}>
            <Sort fontSize="small" htmlColor={colors.text.title} />
          </IconButton>
        )}
        {props.showCollapseButton && (
          <Button
            aria-describedby={"collapse"}
            variant="contained"
            sx={{
              fontSize: "12px",
              padding: "8px 10px",
              lineHeight: "normal",
              background: colors.secondary.lightGrayText,
              marginLeft: "10px",
            }}
          >
            Collapse All
          </Button>
        )}
        {props.showSortButton && (
          <Button
            aria-describedby={"sort"}
            variant="contained"
            endIcon={<ArrowDown />}
            sx={{
              fontSize: "12px",
              padding: "8px 10px",
              lineHeight: "normal",
              marginLeft: "10px",
            }}
          >
            Sort By
          </Button>
        )}
      </Box>
    </GridToolbarContainer>
  );
};

const CustomGridRow = (props: GridRowProps) => {
  const detailPanelContent = React.useMemo(
    () => props?.getDetailPanelContent?.(props.row),
    [props]
  );

  return (
    <React.Fragment>
      <GridRow {...props}>{props.children}</GridRow>
      {detailPanelContent ? (
        <React.Fragment>
          {detailPanelContent}
          <Box height={8} />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export const Table: React.FC<TableProps> = (props: TableProps) => {
  const slots: Partial<GridSlotsComponent> | undefined = React.useMemo(() => {
    if (props.withSearch) {
      return {
        toolbar: CustomGridToolbar,
        row: CustomGridRow,
      };
    }
    return {
      row: CustomGridRow,
    };
  }, [props.withSearch]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "250px",
      }}
    >
      <DataGrid
        hideFooter
        autoHeight={props.autoHeight ?? true}
        rowHeight={46}
        rows={props.rows}
        rowSelection={false}
        columnHeaderHeight={46}
        getRowHeight={props.getRowHeight}
        columns={props.columns}
        columnVisibilityModel={{
          id: false,
        }}
        sx={{
          borderStyle: "none",
          ".MuiDataGrid-columnHeaderTitle": {
            fontSize: props.headerFontSize ?? "14px",
            fontWeight: "700",
            color: colors.text.title,
          },
          ".MuiDataGrid-row": {
            ":hover": {
              background: "transparent",
            },
          },
          ".MuiDataGrid-cell": {
            fontSize: "12px",
            ":focus": {
              outline: "none",
            },
            ":active": {
              outline: "none",
            },
          },
        }}
        slots={slots}
        slotProps={{
          toolbar: {
            showQuickFilter: props.withSearch,
            showFilterButton: props.withFilterButton,
            showAddFileButton: props.withAddFileButton,
            showColumnsButton: props.withColumnsButton,
            showSortButton: props.withSortButton,
            showCollapseButton: props.withCollapseButton,
          },
          row: {
            getDetailPanelContent: props.getDetailPanelContent,
          },
        }}
      />
    </Box>
  );
};
