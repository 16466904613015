import { Box, Grid, Typography } from "@mui/material";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { SimpleTable } from "app/components/simple-table";
import { ACTIVITY_STATUS_COLORS } from "app/pages/explorer/components/search/tableColumns";
import { ACTIVITIES } from "app/pages/home/explorer/components/live-data-updates/data";
import { colors } from "app/theme";
import { get } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";

const DescriptionBlock = () => {
  const { id } = useParams();
  const activity = ACTIVITIES.find((activity) => activity.id === id);
  return (
    <GenericPageBlockItem
      toolbarShare
      title="Overview"
      description={
        <React.Fragment>
          Individual measure for EU foreign policy needs on support to EU Arctic
          policy for 2022 is an
          <span
            style={{
              color: colors.primary.blue,
            }}
          >
            ongoing activity
          </span>{" "}
          published by{" "}
          <span
            style={{
              color: colors.primary.blue,
            }}
          >
            {" "}
            European Comission.{" "}
          </span>{" "}
        </React.Fragment>
      }
      content={
        <React.Fragment>
          <Grid container spacing={"20px"}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Activity Identifier</Typography>
              <Box height={10} />
              <Box
                sx={{
                  padding: "6px 10px",
                  border: "0.5px solid #D7D8D9",
                  background: "white",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={"400"}
                  fontSize={"12px"}
                  lineHeight={"normal"}
                >
                  XI-IATI-EC_FPI-2022/44177/1
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Other Identifiers</Typography>
              <Box height={10} />
              <SimpleTable
                columns={[
                  { headerName: "Code", field: "code" },
                  { headerName: "Text", field: "text" },
                ]}
                rows={[{ code: "2022600001", text: "N/A" }]}
                variant="horizontal"
                boxStyle={{
                  padding: "6px 10px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">Reporting Organisation</Typography>
              <Box height={10} />
              <SimpleTable
                columns={[
                  { headerName: "Identifier", field: "identifier" },
                  { headerName: "Type", field: "type" },
                  { headerName: "Name", field: "name" },
                ]}
                rows={[
                  {
                    identifier: "XI-IATI-EC_FPI",
                    type: "Other Public Sector",
                    name: "European Commission - Service for Foreign Policy Instruments",
                  },
                ]}
                variant="horizontal"
                boxStyle={{
                  padding: "6px 10px",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6">Description</Typography>
              <Box height={10} />
              <SimpleTable
                columns={[
                  { headerName: "Language", field: "language" },
                  { headerName: "General", field: "general" },
                ]}
                rows={[
                  {
                    language: "English",
                    general:
                      "Individual measure for EU foreign policy needs on support to EU Arctic policy for 2022",
                  },
                ]}
                variant="horizontal"
                boxStyle={{
                  padding: "6px 10px",
                }}
                gridTemplateColumns="7.5% 92.5%"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Activity Status & Scope</Typography>
              <Box height={10} />

              <SimpleTable
                columns={[
                  {
                    headerName: "Activity Status",
                    field: "activityStatus",
                    renderCell: (row) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                          flexDirection: "row",
                        }}
                      >
                        <Box
                          sx={{
                            width: "9px",
                            height: "9px",
                            borderRadius: "50%",
                            backgroundColor: get(
                              ACTIVITY_STATUS_COLORS,
                              row?.row?.activityStatus ?? "",
                              "grey"
                            ),
                          }}
                        />
                        <span>{row?.row?.activityStatus}</span>
                      </Box>
                    ),
                  },
                  { headerName: "Scope", field: "scope" },
                ]}
                rows={[
                  {
                    activityStatus: activity?.status,
                    scope: "Global",
                  },
                ]}
                variant="horizontal"
                boxStyle={{
                  padding: "6px 10px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Activity Dates</Typography>
              <Box height={10} />

              <SimpleTable
                columns={[
                  {
                    headerName: "Planned Start Date",
                    field: "planned_start_date",
                  },
                  {
                    headerName: "Planned End Date",
                    field: "planned_end_date",
                  },
                  {
                    headerName: "Actual Start Date",
                    field: "actual_start_date",
                  },
                  {
                    headerName: "Actual End Date",
                    field: "actual_end_date",
                  },
                ]}
                rows={[
                  {
                    planned_start_date: "2023-12-31",
                    planned_end_date: "2023-12-31",
                    actual_start_date: "2023-12-31",
                    actual_end_date: "N/A",
                  },
                ]}
                variant="horizontal"
                boxStyle={{
                  padding: "6px 10px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">Contact Information</Typography>
              <Box height={10} />
              <Grid container spacing={"20px"}>
                <Grid item xs={12} md={6}>
                  <SimpleTable
                    columns={[
                      { headerName: "Type", field: "type" },
                      { headerName: "Telephone", field: "telephone" },
                      { headerName: "Email", field: "email" },
                      { headerName: "Website", field: "website" },
                    ]}
                    rows={[
                      {
                        type: "General Inquiries",
                        telephone: "(+32) 2 584 11 11",
                        email:
                          "http://ec.europa.eu/dgs/fpi/about/contact_us_en.htm",
                        website: "http://ec.europa.eu/dgs/fpi/index_en.htm",
                      },
                    ]}
                    variant="vertical"
                    boxStyle={{
                      padding: "6px 10px",
                    }}
                    gridTemplateColumns="25% 75%"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SimpleTable
                    columns={[
                      {
                        headerName: "Organisation",
                        field: "organisation",
                      },
                      { headerName: "Department", field: "department" },
                      {
                        headerName: "Person & Position",
                        field: "person_position",
                      },

                      {
                        headerName: "Emailing Address",
                        field: "emailing_address",
                      },
                    ]}
                    rows={[
                      {
                        organisation:
                          "EC - Service for Foreign Policy Instruments (FPI)",
                        department:
                          "EC - Service for Foreign Policy Instruments (FPI)",
                        person_position: "N/A",
                        emailing_address: "N/A",
                      },
                    ]}
                    variant="vertical"
                    boxStyle={{
                      padding: "6px 10px",
                    }}
                    gridTemplateColumns="25% 75%"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      }
    />
  );
};

export default DescriptionBlock;
