export const SECTORS = [
  {
    id: "1",
    locations: 123,
    incomingFunds: "USD 8 MLN",
    activities: 2890,
    organisations: 123,
    sector: "Acting Relating to Debt",
    name: "Acting Relating to Debt",
  },
  {
    id: "2",
    locations: 123,
    incomingFunds: "USD 8 MLN",
    activities: 3409,
    organisations: 23,
    sector: "Administrative Costs of Donors",
    name: "Administrative Costs of Donors",
  },

  {
    id: "3",
    locations: 123,
    incomingFunds: "USD 8 MLN",
    activities: 2890,
    organisations: 123,
    sector: "Agriculture",
    name: "Agriculture",
  },
];
