import React, { useCallback, useMemo } from "react";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerOrganisationsHeaderIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ORGANISATIONS } from "app/pages/explorer/pages/organisations/data";
import { Box, Grid, SelectChangeEvent } from "@mui/material";
import { PageHeader } from "app/components/page-header";
import { SelectField } from "app/components/select-field";
import TransactionTypeSelect from "app/pages/explorer/components/transaction-type";
import { YearSelectButton } from "app/components/year-select-button";
import { ShareButton } from "app/components/share-button";
import { DownloadButton } from "app/components/download-button";
import { InsightsBlock } from "./components/insights-block";
import { colors } from "app/theme";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { Table } from "app/components/table";
import { ACTIVITIES } from "app/pages/home/explorer/components/live-data-updates/data";
import { ACTIVITIES_COLUMNS } from "app/pages/home/explorer/components/live-data-updates/tableColumns";
import { DATASETS, getDatasetColumns } from "./tableData";
import { cloneDeep, set } from "lodash";
import { DetailPanelContent } from "./components/detail-panel-content";

const ExplorerOrganisation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const organisation = ORGANISATIONS.find((org) => org.iatiIdentifier === id);
  const handleOrganisationChange = (e: SelectChangeEvent) => {
    navigate(`/explorer/organisations/${e.target.value}`);
  };

  const [expandedBlock, setExpandedBlock] = React.useState<
    "locations" | "results" | "sdgs" | "networks" | "sectors" | "impact" | null
  >(null);

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    setExpandedBlock((prev) => (prev === block ? null : block));
  };
  const [expandedRowIds, setExpandedRowIds] = React.useState<string[]>([]);

  const handleExpandRow = (rowId: string, field: string) => {
    const newExpandedRowIds = cloneDeep(expandedRowIds);
    const index = newExpandedRowIds.indexOf(`${rowId}.${field}`);
    if (index === -1) {
      newExpandedRowIds.push(`${rowId}.${field}`);
    } else {
      newExpandedRowIds.splice(index, 1);
    }
    setExpandedRowIds(newExpandedRowIds);
  };

  const detailPanelContent = useCallback(
    (row: any) => {
      return <DetailPanelContent row={row} expandedRowIds={expandedRowIds} />;
    },
    [expandedRowIds]
  );
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title={organisation?.name!}
        subtitle={`Showcasing organisation-specific data of ${organisation?.name}.`}
        icon={<ExplorerOrganisationsHeaderIcon />}
      />
      <Box height={30} />
      <Grid
        justifyContent={"space-between"}
        alignItems={"center"}
        display={"flex"}
      >
        <SelectField
          id="location-select"
          options={ORGANISATIONS.map((s) => ({
            label: s.name,
            value: String(s.iatiIdentifier),
          }))}
          placeholder="Select Another Location"
          value={""}
          onChange={handleOrganisationChange}
          variant="outlined"
          width="300px"
        />
        <Grid display={"flex"} alignItems={"center"} columnGap={"8px"}>
          <TransactionTypeSelect />
          <YearSelectButton />
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      <Box height={30} />
      <InsightsBlock
        expandedBlock={expandedBlock}
        setExpandedBlock={handleSetExpandedBlock}
      />
      <Box height={30} />
      <GenericPageBlockItem
        title="Recent Activities"
        description={
          <React.Fragment>
            Currently{" "}
            <span style={{ color: colors.primary.blue }}>
              1,908 active activities{" "}
            </span>
            taking place for {organisation?.name} in 2022.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              withSearch
              withSortButton
              rows={ACTIVITIES}
              columns={ACTIVITIES_COLUMNS}
              headerFontSize="12px"
            />
          </React.Fragment>
        }
        toolbarShare
      />

      <Box height={30} />
      <GenericPageBlockItem
        title="IATI CLOUD DATA / ORGANISATION DATASETS "
        description={
          <React.Fragment>
            Currently{" "}
            <span style={{ color: colors.primary.blue }}>20 datasets</span>{" "}
            found for {organisation?.name} in the IATI registry.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              autoHeight={false}
              withSearch
              withSortButton
              withCollapseButton
              rows={DATASETS}
              columns={getDatasetColumns(handleExpandRow, expandedRowIds)}
              headerFontSize="12px"
              getDetailPanelContent={detailPanelContent}
            />
          </React.Fragment>
        }
        toolbarShare
      />
    </Box>
  );
};

export default ExplorerOrganisation;
