export interface LineChartProps {
  id: string;
  // data: { x: string | number; y: number }[];
  data: {
    xAxisValues: string[];
    series: { name: string; values: number[] }[];
  };
  yAxisLabelName: string;
  height?: string;
  width?: string;
  variant?: "default" | "signalLine";
  hideXaxisLine?: boolean;
  lineColor?: string;
  symbolSize?: number;
  showLegend?: boolean;
  step?: "start" | "middle" | "end";
  lineThickness?: number;
}

export const LineChartSampleData: LineChartProps["data"] = {
  xAxisValues: ["2018", "2019", "2020", "2021", "2022"],
  series: [
    {
      name: "% of Success",
      values: [0.2, 1.5, -3, 2.5, 3.5],
    },
  ],
};

export const SignalLineChartSampleData: LineChartProps["data"] = {
  xAxisValues: ["2020", "2021", "2022", "2023", "2024"],
  series: [
    {
      name: "USD",
      values: [20000, 15000, 30000, 25000, 35000],
    },
  ],
};

export const TransactionsLineChartSampleData: LineChartProps["data"] = {
  xAxisValues: ["2011", "2012", "2013", "2014"],
  series: [
    {
      name: "Incoming Funds",
      values: [110000, 120000, 140000, 140000],
    },
    {
      name: "Commitment",
      values: [130000, 150000, 150000, 150000],
    },
    {
      name: "Disbursement",
      values: [80000, 80000, 100000, 100000],
    },
    {
      name: "Expenditure",
      values: [60000, 100000, 120000, 120000],
    },
    {
      name: "Interest Repayment",
      values: [40000, 50000, 60000, 60000],
    },
    {
      name: "Loan Repayment",
      values: [20000, 20000, 20000, 20000],
    },
    {
      name: "Reimbursement",
      values: [15000, 30000, 30000, 30000],
    },
    {
      name: "Sale of Equity",
      values: [40000, 40000, 50000, 50000],
    },
    {
      name: "Credit Guarantee",
      values: [50000, 50000, 60000, 60000],
    },
    {
      name: "Incoming Commitment",
      values: [60000, 80000, 40000, 40000],
    },
  ],
};
